<template>
  <div class="container">
    <pcPublicHeader />
    <div class="page-bg-item img-cover">
      <img src="../../assets/pc-images/vip-bg.png" />
    </div>
    <div class="public-content">
      <div class="vip-list-item">
        <div class="vip-item img-contain" v-for="(item, index) in vipList" :key="index" @click="handleSubmit(item.Level)">
          <img :src="item.Banner" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import pcPublicHeader from '@/components/pcPublicHeader'

export default {
  name: "Vip",
  components: {
    pcPublicHeader
  },
  data() {
    return {
      vipList: []
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  filters: {
    
  },
  mounted() {
    this.handleGetVipList()
  },
  methods: {
    ...mapActions(['GetUserInfo']),
    async handleGetVipList() {
      const { userInfo } = this
      var res = await this.$API.vipMoney.post({
        Type: 1
      })
      if (res && res.length > 0) {
        const list = res.filter((item) => {
          item.Qy = JSON.parse(item.Qy || '[]')
          return item.Level > userInfo.Level
        })
        // if (list.length == 0) {
        //   list.push(res[res.length - 1])
        // }
        this.vipList = list
      }
    },
    handleSubmit(level) {
      this.$dialog.confirm({
        type: 'info',
        className: 'pc-dialog',
        title: '确认开通',
        message: '确认开通？将从账户余额直接扣款',
        cancelButtonText: '取消',
        confirmButtonText: '确认',
      }).then(async () => {
        var res = await this.$API.buyVip.post({
          Level: level
        })
        this.submiting = false
        if (res.error == 0) {
          this.$toast.success(res.info)
          await this.GetUserInfo()
          this.handleGetVipList()
        } else {
          this.$dialog.alert({
            type: 'danger',
            className: 'pc-dialog',
            title: '提示',
            message: res.info,
            confirmButtonText: '确定',
          })
        }
      })
    }
  }
};
</script>

<style lang="less" scoped>
.page-bg-item {
  width: 100%;
  height: 300px;
  margin-bottom: -106px;
}
.vip-list-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  .vip-item {
    cursor: pointer;
    width: 340px;
    margin-right: 40px;
    margin-bottom: 40px;
    &:nth-child(4n) {
      margin-right: 0;
    }
  }
}
</style>
